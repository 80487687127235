import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  photoList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  photo: {
    width: '32.5%',
    paddingBottom: 10,
  },
});
