import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  sideTitle: {
    fontWeight: 'medium',
    fontSize: 13,
    color: '#545454',
    paddingBottom: 12,
  },
  referencePhotoContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  referencePhoto: {
    width: '70%',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cell: {
    paddingLeft: 16,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerRow: {
    paddingVertical: 6,
    backgroundColor: '#F5F5F5',
    fontSize: 10,
    fontWeight: 'medium',
    color: '#212121',
    marginTop: 16,
  },
  headerCell: {
    fontSize: 10,
    flex: 1,
  },
  damagesRowsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  damagesRow: {
    color: '#545454',
    paddingVertical: 18,
  },
  damagesCell: {
    fontSize: 12,
    flex: 1,
  },
  damageTypeSeparator: {
    marginHorizontal: 4,
  },
  damageTypeColorIndicator: {
    width: 10,
    height: 10,
    marginRight: 4,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#212121',
  },
  vehiclePartContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  vehiclePartId: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#545454',
    color: '#212121',
    marginRight: 8,
  },
  croppedPhotosRow: {
    justifyContent: 'flex-start',
  },
  croppedPhotoLabel: {
    width: '15%',
    color: '#545454',
    fontSize: 11,
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 10,
  },
  croppedPhoto: {
    width: '28%',
    marginHorizontal: 2,
  },
});
