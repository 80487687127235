import { useI18nSync, useInteractiveStatus } from '@monkvision/common';
import { Button } from '@monkvision/common-ui-web';
import { ImageDetailedViewProps, useImageDetailedViewStyles } from './hooks';
import { ImageDetailedViewOverlay } from './ImageDetailedViewOverlay';

/**
 * This component is used to display the preview of an inspection image, as well as additional data such as its label
 * etc. If this component is used mid-capture, set the `captureMode` prop to `true` so that you'll enable features such
 * as compliance errors, retakes etc.
 */
export function ImageDetailedView(props: ImageDetailedViewProps) {
  useI18nSync(props.lang);
  const { status, eventHandlers } = useInteractiveStatus();
  const {
    mainContainerStyle,
    leftContainerStyle,
    overlayContainerStyle,
    rightContainerStyle,
    closeButton,
    galleryButton,
    cameraButton,
  } = useImageDetailedViewStyles(props, status);

  return (
    <div style={mainContainerStyle} {...eventHandlers}>
      <div style={leftContainerStyle}>
        <Button
          onClick={props.onClose}
          icon='close'
          size='small'
          primaryColor={closeButton.primaryColor}
          secondaryColor={closeButton.secondaryColor}
        />
      </div>
      <div style={overlayContainerStyle}>
        <ImageDetailedViewOverlay
          image={props.image}
          captureMode={props.captureMode}
          reportMode={!!props.reportMode}
          onRetake={props.captureMode ? props.onRetake : undefined}
        />
      </div>
      <div style={rightContainerStyle}>
        {props.reportMode ? (
          <Button
            disabled={!(props.showGalleryButton ?? true)}
            onClick={props.onShowDamage}
            icon={props.showDamage ? 'visibility-off' : 'visibility-on'}
            primaryColor={galleryButton.primaryColor}
            secondaryColor={galleryButton.secondaryColor}
            style={galleryButton.style}
          >
            {props.showDamage ? 'Hide Damage' : 'Show Damage'}
          </Button>
        ) : (
          <Button
            disabled={!(props.showGalleryButton ?? true)}
            onClick={props.onNavigateToGallery}
            icon='gallery'
            primaryColor={galleryButton.primaryColor}
            secondaryColor={galleryButton.secondaryColor}
            style={galleryButton.style}
          />
        )}
        <Button
          disabled={!props.captureMode || !(props.showCaptureButton ?? true)}
          onClick={props.captureMode ? props.onNavigateToCapture : undefined}
          icon='camera-outline'
          style={cameraButton.style}
        />
      </div>
    </div>
  );
}
