import { Styles } from '@monkvision/types';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
