import { Image, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { styles } from './TopBar.styles';

export interface TopBarProps {
  lang: string;
}

export function TopBar({ lang }: TopBarProps) {
  const { t } = useTranslation('translation', { lng: lang });

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('pdf.topBar.title')}</Text>
      <Image src='/tesla_pdf_logo.png' style={styles.logo} />
    </View>
  );
}
