import { PropsWithChildren, ReactElement } from 'react';
import { Page, View } from '@react-pdf/renderer';
import { styles } from './TeslaPdfPage.styles';

export interface TeslaPdfPage {
  header?: ReactElement;
  footer?: ReactElement;
}

export function TeslaPdfPage({ header, footer, children }: PropsWithChildren<TeslaPdfPage>) {
  return (
    <Page size='A4'>
      {header}
      <View fixed style={styles.verticalPageMargin} />
      <View style={styles.contentContainer}>{children}</View>
      <View fixed style={styles.verticalPageMargin} />
      {footer}
    </Page>
  );
}
