import { Image, View } from '@react-pdf/renderer';
import { styles } from './Footer.styles';

export function Footer() {
  return (
    <View style={styles.container}>
      <Image src='/tesla_pdf_logo_light.png' style={styles.logo} />
    </View>
  );
}
