import { Styles } from '@monkvision/types';

export const styles: Styles = {
  container: {
    // width: '150px',
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    opacity: '0.7',
    paddingBottom: '8px',
  },
  doneBtn: {
    borderRadius: '9px',
    width: '200px',
  },
};
