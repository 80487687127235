import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import {
  damageTypeLabels,
  flatMap,
  useObjectTranslation,
  vehiclePartLabels,
} from '@monkvision/common';
import { styles } from './Summary.styles';
import { SectionTitle } from '../SectionTitle';
import { PdfVehicleSideData } from '../../../hooks/useTeslaPdfGenerator/types';
import { formatPrice } from '../../../utils';

export interface SummaryProps {
  damagedSides: PdfVehicleSideData[];
  currency: string;
  lang: string;
}

export function Summary({ damagedSides, currency, lang }: SummaryProps) {
  const { t } = useTranslation('translation', { lng: lang });
  const { tObj } = useObjectTranslation('translation', { lng: lang });

  const parts = flatMap(damagedSides, ({ damagedParts }) => damagedParts).sort(
    (a, b) => b.repairCost - a.repairCost,
  );
  const totalPrice = parts.reduce((prev, { repairCost }) => prev + repairCost, 0);

  return (
    <View style={styles.container}>
      <SectionTitle title={t('pdf.summary.title')} />
      <Text style={styles.tableTitle}>{t('pdf.summary.repairPrice.title')}</Text>
      <View style={{ ...styles.row, ...styles.headerRow }}>
        <View style={styles.cell}>
          <Text>{t('pdf.summary.repairPrice.vehiclePart')}</Text>
        </View>
        <View style={styles.cell}>
          <Text>{t('pdf.summary.repairPrice.damageType')}</Text>
        </View>
        <View style={{ ...styles.cell, ...styles.priceCell }}>
          <Text>{t('pdf.summary.repairPrice.price')}</Text>
        </View>
      </View>
      {parts.map(({ name, damages, repairCost }) => (
        <View key={name} style={styles.row}>
          <View style={styles.cell}>
            <Text>{tObj(vehiclePartLabels[name])}</Text>
          </View>
          <View style={styles.cell}>
            <Text>{damages.map((type) => tObj(damageTypeLabels[type])).join(', ')}</Text>
          </View>
          <View style={{ ...styles.cell, ...styles.priceCell }}>
            <Text>{formatPrice(repairCost, currency, lang)}</Text>
          </View>
        </View>
      ))}
      <View style={styles.totalRow}>
        <Text>{t('pdf.summary.repairPrice.totalRepairPrice')}</Text>
        <Text style={styles.totalPrice}>{formatPrice(totalPrice, currency, lang)}</Text>
      </View>
    </View>
  );
}
