import { DamageType, Sight, VehiclePart } from '@monkvision/types';

export interface PdfVehicleDetails {
  model: string;
  vin: string;
  inspectionDate: Date | null;
  mileage: number;
  totalCost: number;
  beautyShotUrl: string;
}

export interface PdfPhoto {
  url: string;
  sight: Sight;
}

export interface PdfDamagedPart {
  id: number;
  name: VehiclePart;
  damages: DamageType[];
  repairCost: number;
  croppedPhotoUrls: string[];
}

export enum PdfVehicleSide {
  LEFT = 'left',
  FRONT = 'front',
  RIGHT = 'right',
  REAR = 'rear',
}

export interface PdfVehicleSideData {
  side: PdfVehicleSide;
  referencePhotoUrl: string;
  damagedParts: PdfDamagedPart[];
}

export interface PdfInspectionData {
  inspectionId: string;
  vehicle: PdfVehicleDetails;
  photos: PdfPhoto[];
  damagedSides: PdfVehicleSideData[];
}

export const SIGHT_LABELS_ORDERED = [
  'front-low',
  'front-bumper-side-right',
  'front-fender-right',
  'lateral-full-right',
  'rear-lateral-right',
  'rear-right',
  'rear-low',
  'rear-left',
  'rear-lateral-left',
  'lateral-full-left',
  'front-roof-left',
  'front-bumper-side-left',
  'front-fender-left',
  'tire',
  'front-seats',
  'trunk',
  'keys',
  'dashboard-screen',
];

export const BEAUTY_SHOT_SIGHT_LABEL = 'front-fender-left';

export const SIDE_REFERENCE_SIGHT_LABEL: Record<PdfVehicleSide, string> = {
  [PdfVehicleSide.LEFT]: 'lateral-full-left',
  [PdfVehicleSide.FRONT]: 'front-low',
  [PdfVehicleSide.RIGHT]: 'lateral-full-right',
  [PdfVehicleSide.REAR]: 'rear-low',
};

export const IGNORED_VEHICLE_PARTS = [
  VehiclePart.MIRROR_SUPPORT,
  VehiclePart.PILLAR,
  VehiclePart.HUBCAP,
  VehiclePart.ROCKER_PANEL,
  VehiclePart.WIPER,
  VehiclePart.WHEEL,
  VehiclePart.CAR_INSIDE,
  VehiclePart.DAMAGED_CAR_INSIDE,
  VehiclePart.BACKGROUND,
  VehiclePart.IGNORE,

  /* For now, we also ignore wheels until the back-end tem fixes the wheels pricing V2 */
  VehiclePart.WHEEL_BACK_LEFT,
  VehiclePart.WHEEL_BACK_RIGHT,
  VehiclePart.WHEEL_FRONT_LEFT,
  VehiclePart.WHEEL_FRONT_RIGHT,
] as const;

export type ValidVehiclePart = Exclude<VehiclePart, (typeof IGNORED_VEHICLE_PARTS)[number]>;

export const VEHICLE_PARTS_SIDE: Record<ValidVehiclePart, PdfVehicleSide> = {
  [VehiclePart.BUMPER_BACK]: PdfVehicleSide.REAR,
  [VehiclePart.BUMPER_FRONT]: PdfVehicleSide.FRONT,
  [VehiclePart.DOOR_BACK_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.DOOR_BACK_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.DOOR_FRONT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.DOOR_FRONT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.FENDER_BACK_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.FENDER_BACK_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.FENDER_FRONT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.FENDER_FRONT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.FOG_LIGHT_BACK_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.FOG_LIGHT_BACK_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.FOG_LIGHT_FRONT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.FOG_LIGHT_FRONT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.GRILL]: PdfVehicleSide.FRONT,
  [VehiclePart.GRILL_LOW]: PdfVehicleSide.FRONT,
  [VehiclePart.GRILL_RADIATOR]: PdfVehicleSide.FRONT,
  [VehiclePart.HANDLE_BACK_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.HANDLE_BACK_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.HANDLE_FRONT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.HANDLE_FRONT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.HEAD_LIGHT_LEFT]: PdfVehicleSide.FRONT,
  [VehiclePart.HEAD_LIGHT_RIGHT]: PdfVehicleSide.FRONT,
  [VehiclePart.HEADER_PANEL]: PdfVehicleSide.FRONT,
  [VehiclePart.HOOD]: PdfVehicleSide.FRONT,
  [VehiclePart.HOOK]: PdfVehicleSide.REAR,
  [VehiclePart.HUBCAP_BACK_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.HUBCAP_BACK_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.HUBCAP_FRONT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.HUBCAP_FRONT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.INDICATOR_LIGHT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.INDICATOR_LIGHT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.LICENSE_PLATE_BACK]: PdfVehicleSide.REAR,
  [VehiclePart.LICENSE_PLATE_FRONT]: PdfVehicleSide.FRONT,
  [VehiclePart.LOGO]: PdfVehicleSide.FRONT,
  [VehiclePart.MIRROR_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.MIRROR_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.PETROL_DOOR]: PdfVehicleSide.LEFT,
  [VehiclePart.QUARTER_WINDOW_BACK_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.QUARTER_WINDOW_BACK_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.QUARTER_WINDOW_FRONT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.QUARTER_WINDOW_FRONT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.REAR_SPOILER]: PdfVehicleSide.REAR,
  [VehiclePart.ROCKER_PANEL_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.ROCKER_PANEL_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.ROOF]: PdfVehicleSide.FRONT,
  [VehiclePart.TAIL_LIGHT_CENTER]: PdfVehicleSide.REAR,
  [VehiclePart.TAIL_LIGHT_LEFT]: PdfVehicleSide.REAR,
  [VehiclePart.TAIL_LIGHT_RIGHT]: PdfVehicleSide.REAR,
  [VehiclePart.TRUNK]: PdfVehicleSide.REAR,
  [VehiclePart.TURN_SIGNAL_FRONT_LATERAL_LEFT]: PdfVehicleSide.FRONT,
  [VehiclePart.TURN_SIGNAL_FRONT_LATERAL_RIGHT]: PdfVehicleSide.FRONT,
  [VehiclePart.WINDOW_BACK_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.WINDOW_BACK_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.WINDOW_CORNER_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.WINDOW_CORNER_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.WINDOW_FRONT_LEFT]: PdfVehicleSide.LEFT,
  [VehiclePart.WINDOW_FRONT_RIGHT]: PdfVehicleSide.RIGHT,
  [VehiclePart.WINDSHIELD_BACK]: PdfVehicleSide.REAR,
  [VehiclePart.WINDSHIELD_FRONT]: PdfVehicleSide.FRONT,
  [VehiclePart.WIPER_BACK]: PdfVehicleSide.REAR,
  [VehiclePart.WIPER_FRONT]: PdfVehicleSide.FRONT,
  [VehiclePart.FRONT_SPOILER]: PdfVehicleSide.FRONT,
  [VehiclePart.HANDLE_BACK_CENTER]: PdfVehicleSide.REAR,
};

export function isValidVehiclePart(part: string | undefined | null): part is ValidVehiclePart {
  return !!part && Object.keys(VEHICLE_PARTS_SIDE).includes(part);
}

export const DAMAGE_COLORS: Record<DamageType, string> = {
  [DamageType.SCRATCH]: 'rgb(255,46,255)',
  [DamageType.DENT]: 'rgb(55,197,31)',
  [DamageType.SMASH]: 'rgb(0,70,253)',
  [DamageType.BODY_CRACK]: 'rgb(255,56,56)',
  [DamageType.RUSTINESS]: 'rgb(255,157,46)',
  [DamageType.BROKEN_LIGHT]: 'rgb(0,255,248)',
  [DamageType.MISSING_PIECE]: 'rgb(255,243,26)',
  [DamageType.BROKEN_GLASS]: 'rgb(44,94,0)',
  [DamageType.HUBCAP_SCRATCH]: 'rgb(94,0,255)',
  [DamageType.MISSING_HUBCAP]: 'rgb(101,53,0)',
};
