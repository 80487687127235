import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#161717',
  },
  title: {
    color: '#ffffff',
    fontSize: 20,
  },
  logo: {
    height: 15,
  },
});
