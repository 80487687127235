import { Image, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { styles } from './VehicleDetails.styles';
import { formatNumber, formatPrice } from '../../../utils';
import { PdfVehicleDetails } from '../../../hooks/useTeslaPdfGenerator/types';

export interface VehicleDetailsProps {
  vehicle: PdfVehicleDetails;
  currency: string;
  lang: string;
}

function formatInspectionDate(inspectionDate: Date | null, lang: string): string {
  if (!inspectionDate) {
    return '';
  }
  return DateTime.fromJSDate(inspectionDate).setLocale(lang).toLocaleString(DateTime.DATETIME_FULL);
}

export function VehicleDetails({ vehicle, currency, lang }: VehicleDetailsProps) {
  const { t } = useTranslation('translation', { lng: lang });

  return (
    <View style={styles.container}>
      <View style={styles.detailsContainer}>
        <View>
          <Text style={styles.modelText}>{vehicle.model}</Text>
          <Text style={styles.text}>{`VIN# ${vehicle.vin}`}</Text>
        </View>
        <View>
          <Text style={styles.label}>{t('pdf.vehicleDetails.inspectionDate')}</Text>
          <Text style={styles.text}>{formatInspectionDate(vehicle.inspectionDate, lang)}</Text>
        </View>
        <View style={styles.numbersRow}>
          <View style={styles.numbersCol}>
            <Text style={styles.label}>{t('pdf.vehicleDetails.mileage')}</Text>
            <Text style={{ ...styles.text, ...styles.numberText }}>
              {formatNumber(vehicle.mileage, lang)}
            </Text>
          </View>
          <View style={styles.numbersCol}>
            <Text style={styles.label}>{t('pdf.vehicleDetails.totalCost')}</Text>
            <Text style={{ ...styles.text, ...styles.numberText, ...styles.totalCost }}>
              {formatPrice(vehicle.totalCost, currency, lang)}
            </Text>
          </View>
        </View>
      </View>
      <Image src={vehicle.beautyShotUrl} style={styles.beautyShot} />
    </View>
  );
}
