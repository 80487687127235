import { useState } from 'react';
import { clsx } from 'clsx';
import { validate } from 'uuid';
import { Button, Checkbox } from '@monkvision/common-ui-web';
import { useMonitoring } from '@monkvision/monitoring';
import { useLoadingState } from '@monkvision/common';
import styles from './PdfGeneratorPage.module.css';
import { useTeslaPdfGenerator } from '../../hooks';

export function PdfGeneratorPage() {
  const [inspectionId, setInspectionId] = useState('');
  const [touched, setTouched] = useState(false);
  const [openInNewTab, setOpenInNewTab] = useState(false);
  const isValid = validate(inspectionId);
  const { handleError } = useMonitoring();
  const loading = useLoadingState();
  const { generateAndDownloadPdf } = useTeslaPdfGenerator({ openInNewTab });

  const handleGeneratePdf = async () => {
    loading.start();
    try {
      await generateAndDownloadPdf(inspectionId);
      loading.onSuccess();
    } catch (err) {
      handleError(err);
      loading.onError(err);
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['inputContainer']}>
        <div className={styles['label']}>Inspection ID :</div>
        <input
          className={clsx(
            styles['input'],
            { [styles['invalid']]: !isValid },
            { [styles['touched']]: touched },
          )}
          value={inspectionId}
          onFocus={() => setTouched(true)}
          onChange={(e) => setInspectionId(e.target.value)}
        />
      </div>
      <div className={styles['inputContainer']}>
        <Checkbox checked={openInNewTab} onChange={setOpenInNewTab} />
        <div className={styles['label']}>Open PDF in new tab</div>
      </div>
      {/* <Button onClick={handleGeneratePdf} disabled={!isValid} loading={loading}> */}
      <Button onClick={handleGeneratePdf} loading={loading}>
        Generate PDF
      </Button>
      {loading.error && (
        <div className={styles['errorMessage']}>
          An error occurred during the PDF generation. See the console for more details.
        </div>
      )}
    </div>
  );
}
