import { Damage, DamageType, Image, Inspection, VehiclePart, View } from '@monkvision/types';
import { MonkState } from '@monkvision/common';
import { sights } from '@monkvision/sights';
import {
  BEAUTY_SHOT_SIGHT_LABEL,
  isValidVehiclePart,
  PdfDamagedPart,
  PdfInspectionData,
  PdfPhoto,
  PdfVehicleDetails,
  PdfVehicleSide,
  PdfVehicleSideData,
  SIDE_REFERENCE_SIGHT_LABEL,
  SIGHT_LABELS_ORDERED,
  ValidVehiclePart,
  VEHICLE_PARTS_SIDE,
} from './types';
import {
  CroppedPhotoDamage,
  drawCroppedPhotoImage,
  drawSideReferencePhotoImage,
  IndexedBoundingBox,
  ResizedImage,
  resizeImage,
} from './imageProcessing';

interface Dampart {
  part: ValidVehiclePart;
  damageTypes: DamageType[];
  repairCost: number;
}

interface PartImageScore {
  uniqueVisibleDamageTypes: number;
  partSize: number;
}

type PartialDamagedPart = Omit<PdfDamagedPart, 'id' | 'croppedPhotoUrls'>;

class IdIncrement {
  private val = 0;

  public get(): number {
    this.val += 1;
    return this.val;
  }
}

function getInspectionImages(inspection: Inspection, entities: MonkState): Promise<ResizedImage[]> {
  const imagesBySightLabel: Record<string, Image> = {};

  entities.images.forEach((image) => {
    if (image.inspectionId === inspection.id && image.sightId) {
      const sightLabel = sights[image.sightId]?.label;
      if (!sightLabel) {
        return;
      }
      const { createdAt } = image;
      const existingImage = imagesBySightLabel[sightLabel];
      const existingCreatedAt = existingImage?.createdAt;

      if (
        !existingImage ||
        (createdAt && (!existingCreatedAt || new Date(existingCreatedAt) < new Date(createdAt)))
      ) {
        imagesBySightLabel[sightLabel] = image;
      }
    }
  });

  const originalImages = Object.entries(imagesBySightLabel)
    .map(([label, image]) => ({ image, sortIndex: SIGHT_LABELS_ORDERED.indexOf(label) }))
    .sort((a, b) => a.sortIndex - b.sortIndex)
    .map(({ image }) => image);

  return Promise.all(originalImages.map((image) => resizeImage(image)));
}

function getInspectionDate(inspection: Inspection, images: ResizedImage[]): Date | null {
  let inspectionDate: Date | null = null;
  images.forEach((image) => {
    if (image.inspectionId === inspection.id && image.additionalData?.created_at) {
      const date = new Date(image.additionalData.created_at);
      inspectionDate = !inspectionDate || inspectionDate < date ? date : inspectionDate;
    }
  });
  return inspectionDate;
}

function generateVehicleDetails(
  inspection: Inspection,
  images: ResizedImage[],
  damparts: Dampart[],
  entities: MonkState,
): PdfVehicleDetails {
  const vehicle = entities.vehicles.find((v) => v.inspectionId === inspection.id);
  if (!vehicle) {
    throw new Error('Vehicle is undefined in Tesla PDF.');
  }

  return {
    model: vehicle.model ?? '',
    vin: vehicle.vin ?? '',
    inspectionDate: getInspectionDate(inspection, images),
    mileage: vehicle.mileageValue ?? 0,
    totalCost: damparts.reduce((prev, curr) => prev + curr.repairCost, 0),
    beautyShotUrl:
      images.find(
        (image) =>
          image.additionalData?.sight_id &&
          sights[image.additionalData.sight_id]?.label === BEAUTY_SHOT_SIGHT_LABEL,
      )?.path ?? images[0].path,
  };
}

function generatePdfPhotos(images: ResizedImage[]): PdfPhoto[] {
  return images.map((image) => ({ url: image.path, sight: sights[image.sightId ?? ''] }));
}

function generateDamparts(inspection: Inspection, entities: MonkState): Dampart[] {
  return Object.keys(inspection.pricing?.details ?? {})
    .filter((part) => isValidVehiclePart(part))
    .map((partName) => {
      const validPartName = partName as ValidVehiclePart;
      const damageTypes = new Set<DamageType>();
      const part = entities.parts.find(
        (p) => p.inspectionId === inspection.id && p.type === validPartName,
      );
      entities.damages.forEach((damage) => {
        if (
          damage.inspectionId === inspection.id &&
          (damage.parts as (string | undefined)[]).includes(part?.id)
        ) {
          damageTypes.add(damage.type);
        }
      });

      return {
        part: validPartName,
        damageTypes: Array.from(damageTypes),
        repairCost: inspection.pricing?.details[partName].pricing ?? 0,
      };
    });
}

function getPartImageScore(name: VehiclePart, image: Image, entities: MonkState): PartImageScore {
  const part = entities.parts.find((p) => p.type === name);
  if (!part) {
    return { uniqueVisibleDamageTypes: 0, partSize: 0 };
  }
  const partDamages = part.damages
    .map((damageId) => entities.damages.find((damage) => damage.id === damageId))
    .filter((damage) => !!damage) as Damage[];
  const imageViews = entities.views.filter((view) => image.views.includes(view.id));
  const visibleDamageTypes = new Set();
  let partSize = 0;
  imageViews.forEach((view) => {
    if (view.elementId === part.id && view.imageRegion.specification.boundingBox) {
      const { width, height } = view.imageRegion.specification.boundingBox;
      partSize = width * height;
    } else {
      const damage = partDamages.find((d) => d.id === view.elementId);
      if (damage) {
        visibleDamageTypes.add(damage.type);
      }
    }
  });
  return {
    uniqueVisibleDamageTypes: visibleDamageTypes.size,
    partSize,
  };
}

async function generateCroppedPhotoUrls(
  images: ResizedImage[],
  name: VehiclePart,
  entities: MonkState,
): Promise<string[]> {
  const part = entities.parts.find((p) => p.type === name);
  if (!part) {
    return [];
  }
  const damages = part.damages
    .map((id) => entities.damages.find((damage) => damage.id === id))
    .filter((damage) => !!damage) as Damage[];
  const originalPhotos = images
    .map((image) => ({ image, score: getPartImageScore(name, image, entities) }))
    .filter(({ score }) => score.uniqueVisibleDamageTypes > 0 && score.partSize > 0)
    .sort((a, b) =>
      a.score.uniqueVisibleDamageTypes === b.score.uniqueVisibleDamageTypes
        ? b.score.partSize - a.score.partSize
        : b.score.uniqueVisibleDamageTypes - a.score.uniqueVisibleDamageTypes,
    )
    .map(({ image }) => image)
    .slice(0, 3);

  const promises = originalPhotos.map((image) => {
    const imageViewsByElementId: Record<string, View> = {};
    image.views.forEach((id) => {
      const view = entities.views.find((v) => v.id === id);
      if (view) {
        imageViewsByElementId[view.elementId] = view;
      }
    });
    const croppedPhotoDamages: CroppedPhotoDamage[] = [];
    damages.forEach((damage) => {
      const damageView = imageViewsByElementId[damage.id];
      if (
        damageView?.imageRegion?.specification?.polygons &&
        damageView.imageRegion.specification.boundingBox
      ) {
        croppedPhotoDamages.push({
          type: damage.type,
          polygons: damageView.imageRegion.specification.polygons,
          boundingBox: damageView.imageRegion.specification.boundingBox,
        });
      }
    });

    return drawCroppedPhotoImage(image, croppedPhotoDamages);
  });
  const croppedPhotoUrls = await Promise.all(promises);

  return croppedPhotoUrls.filter((url) => !!url) as string[];
}

async function generateVehicleSideReferencePhotoUrl(
  side: PdfVehicleSide,
  images: ResizedImage[],
  damagedParts: PdfDamagedPart[],
  entities: MonkState,
): Promise<string> {
  const originalImage = images.find(
    (image) => image.sightId && sights[image.sightId]?.label === SIDE_REFERENCE_SIGHT_LABEL[side],
  );
  if (!originalImage) {
    return '';
  }
  const views = originalImage.views
    .map((id) => entities.views.find((view) => view.id === id))
    .filter((view) => view) as View[];
  const boundingBoxes = damagedParts
    .map(({ id, name }) => {
      const part = entities.parts.find((p) => p.type === name);
      if (!part) {
        return null;
      }
      const view = views.find((v) => v.elementId === part.id);
      if (!view?.imageRegion?.specification.boundingBox) {
        return null;
      }
      return { id, boundingBox: view.imageRegion.specification.boundingBox };
    })
    .filter((bbox) => !!bbox) as IndexedBoundingBox[];

  return drawSideReferencePhotoImage(originalImage, boundingBoxes);
}

async function generateVehicleSideDamagedParts(
  partialDamagedParts: PartialDamagedPart[] | undefined,
  id: IdIncrement,
  images: ResizedImage[],
  entities: MonkState,
): Promise<PdfDamagedPart[] | null> {
  if (!partialDamagedParts) {
    return null;
  }

  const promises = partialDamagedParts.map(
    (partialDamagedPart) =>
      new Promise<PdfDamagedPart>((resolve, reject) => {
        generateCroppedPhotoUrls(images, partialDamagedPart.name, entities)
          .then((croppedPhotoUrls) => {
            resolve({
              ...partialDamagedPart,
              id: id.get(),
              croppedPhotoUrls,
            });
          })
          .catch(reject);
      }),
  );

  return Promise.all(promises);
}

async function createVehicleSideData(
  side: PdfVehicleSide,
  images: ResizedImage[],
  damagedParts: PdfDamagedPart[] | null,
  entities: MonkState,
): Promise<PdfVehicleSideData | null> {
  if (!damagedParts) {
    return Promise.resolve(null);
  }
  const referencePhotoUrl = await generateVehicleSideReferencePhotoUrl(
    side,
    images,
    damagedParts,
    entities,
  );

  return {
    side,
    referencePhotoUrl,
    damagedParts,
  };
}

async function generateDamagedVehicleSides(
  images: ResizedImage[],
  damparts: Dampart[],
  entities: MonkState,
): Promise<PdfVehicleSideData[]> {
  const partialDamagedParts: Partial<
    Record<PdfVehicleSide, Omit<PdfDamagedPart, 'id' | 'croppedPhotoUrls'>[]>
  > = {};
  damparts.forEach((dampart) => {
    const partSide = VEHICLE_PARTS_SIDE[dampart.part];
    partialDamagedParts[partSide] = partialDamagedParts[partSide] ?? [];
    partialDamagedParts[partSide]?.push({
      name: dampart.part,
      damages: dampart.damageTypes,
      repairCost: dampart.repairCost,
    });
  });
  Object.keys(partialDamagedParts).forEach((key) => {
    const side = key as PdfVehicleSide;
    partialDamagedParts[side] = partialDamagedParts[side]?.sort(
      (a, b) => b.repairCost - a.repairCost,
    );
  });
  const id = new IdIncrement();
  const promises = Object.values(PdfVehicleSide).map(
    (side) =>
      new Promise<PdfVehicleSideData | null>((resolve, reject) => {
        generateVehicleSideDamagedParts(partialDamagedParts[side], id, images, entities)
          .then((damagedParts) => createVehicleSideData(side, images, damagedParts, entities))
          .then((res) => resolve(res))
          .catch(reject);
      }),
  );
  const vehicleSides = await Promise.all(promises);
  return vehicleSides.filter((vehicleSide) => !!vehicleSide) as PdfVehicleSideData[];
}

export async function generateTeslaPdfInspectionData(
  inspection: Inspection,
  entities: MonkState,
): Promise<PdfInspectionData> {
  const images = await getInspectionImages(inspection, entities);
  const damparts = generateDamparts(inspection, entities);
  const vehicle = generateVehicleDetails(inspection, images, damparts, entities);
  const photos = generatePdfPhotos(images);
  const damagedSides = await generateDamagedVehicleSides(images, damparts, entities);

  return {
    inspectionId: inspection.id,
    vehicle,
    photos,
    damagedSides,
  };
}
