import { Text, View } from '@react-pdf/renderer';
import { styles } from './SectionTitle.styles';

export interface SectionTitleProps {
  title: string;
  paddingTop?: boolean;
}

export function SectionTitle({ title, paddingTop }: SectionTitleProps) {
  return (
    <View style={{ ...styles.container, ...(paddingTop ? styles.paddingTop : {}) }}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.separator} />
    </View>
  );
}
