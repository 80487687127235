export function formatNumber(value: number, lang: string): string {
  const thousandSeparator = lang.startsWith('en') ? ',' : ' ';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
}

export function formatPrice(price: number, currency: string, lang: string): string {
  const isLeftSideCurrency = ['$'].includes(currency);
  const left = isLeftSideCurrency ? currency : '';
  const right = !isLeftSideCurrency ? currency : '';
  return `${left}${formatNumber(price, lang)}${right}`;
}
