import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingBottom: 24,
  },
  paddingTop: {
    paddingTop: 24,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#212121',
    paddingBottom: 8,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#000000',
  },
});
