import { useTranslation } from 'react-i18next';
import { Image, View } from '@react-pdf/renderer';
import { SectionTitle } from '../SectionTitle';
import { styles } from './VehiclePhotos.styles';
import { PdfPhoto } from '../../../hooks/useTeslaPdfGenerator/types';

export interface VehiclePhotosProps {
  photos: PdfPhoto[];
  lang: string;
}

const FILLER = 'filler';

export function VehiclePhotos({ photos, lang }: VehiclePhotosProps) {
  const { t } = useTranslation('translation', { lng: lang });
  const photoUrlsWithFillers: Omit<PdfPhoto, 'sight'>[] = [...photos];
  for (let i = 0; i < photos.length % 3; i++) {
    photoUrlsWithFillers.push({ url: `${FILLER}-${i}` });
  }

  return (
    <View style={styles.container}>
      <SectionTitle title={t('pdf.vehiclePhotos.title')} />
      <View style={styles.photoList}>
        {photoUrlsWithFillers.map(({ url }) =>
          url.startsWith(FILLER) ? (
            <View key={url} style={styles.photo} />
          ) : (
            <Image key={url} src={url} style={styles.photo} />
          ),
        )}
      </View>
    </View>
  );
}
