import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  document: {
    fontFamily: 'Roboto',
  },
  contentContainer: {
    width: '100%',
    paddingHorizontal: 30,
  },
  verticalPageMargin: {
    height: 30,
    width: '100%',
  },
});
